import {AbstractModule} from '../../../lib/com/hellomonday/core/AbstractModule';
import {AbstractView} from '../../views/core/AbstractView';
import {gsap} from "gsap";
import {Globals} from "../../data/Globals";

export class GetTheCodeModule extends AbstractModule {
	private _copyTheCode: HTMLElement;
	private _previewTheCode;
	private _code: HTMLElement;
	private _element;

	private _buttonArea;

	private _step2;
	private _step3;
	private _stepDone;


	private _step2Button;
	private _step3Button;

	private _step2BackButton;
	private _step3BackButton;

	private _goButton;

	private _currentStep = 1;


	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);
		this._element = element;
		this._copyTheCode = this.element.querySelector('.copyTheCode');
		this._previewTheCode = this.element.querySelector('.previewTheCode');

		this._step2 = this._element.querySelector('.step2');
		this._step3 = this._element.querySelector('.step3');
		this._stepDone = this._element.querySelector('.stepDone');


		this._step2Button = this._step2.querySelector('.button');
		this._step3Button = this._step3.querySelector('.button');

		this._step2BackButton = this._step2.querySelector('.back');
		this._step3BackButton = this._step3.querySelector('.back');

		this._goButton = this._step3.querySelector('.goButton');
		this._buttonArea = this._element.querySelector('.buttons');





		this._code = this.element.querySelector('.code');

		this._code.appendChild(Globals.LOAD_STORIES.getCodeDiv());

		this.init();
	}

	private init(): void {
		this._copyTheCode.addEventListener('click', this.copyTheCode);
		this._previewTheCode.addEventListener('click', this.previewTheCode);

		this._step2Button.addEventListener('click', this.gotoStep3);
		this._step3Button.addEventListener('click', this.step3Completed);

		this._step2BackButton.addEventListener('click', this.gotoStep1);
		this._step3BackButton.addEventListener('click', this.copyTheCode);

		this._goButton.addEventListener('click', this.verifyURL)

	}

	private verifyURL = () => {
		var getURL = this._step3.querySelector('.urlInput').value;
		if (getURL.indexOf('https://') === -1) {
			getURL = 'https://' + getURL;
		}
		var createURL = getURL + '?' + Globals.LOAD_STORIES.getTimestamp();
		// @ts-ignore
		window.plausible('CopyTheCode_addURL', {props: {url: getURL + '?' + Globals.LOAD_STORIES.getTimestamp()}})
		window.open(createURL, '_blank');
	}

	private gotoStep3 = () => {
		// @ts-ignore
		window.plausible('CopyTheCode_step3');
	//	var getUniqueURLElement = this._step3.querySelector('.uniqueURL');
	//	getUniqueURLElement.textContent = Globals.LOAD_STORIES.getTimestamp();
		this._step2.style.visibility = 'hidden';
		this._currentStep = 3;
		this._step3.style.visibility = 'visible';
	}

	private step3Completed = () => {
		// @ts-ignore
		window.plausible('CopyTheCode_completed');
		// plausible('Download', {props: {method: 'HTTP', position: 'footer'}})
		this._step2.style.visibility = 'hidden';
		this._currentStep = 4;
		this._step3.style.visibility = 'hidden';
		this._stepDone.style.visibility = 'visible';

		gsap.delayedCall(4, this.gotoStep1);
	}



	private copyTheCode = () => {
		// @ts-ignore
		window.plausible('CopyTheCode')
		navigator.clipboard.writeText(this._code.textContent);
		this._buttonArea.style.visibility = 'hidden';
		this._currentStep = 2;
		this._step2.style.visibility = 'visible';
		this._step3.style.visibility = 'hidden';

		gsap.to(this._code, {opacity: 0.05, duration: 0.3});
	}


	private gotoStep1 = () => {
		//window.plausible('CopyTheCode')
		//navigator.clipboard.writeText(this._code.textContent);
		this._buttonArea.style.visibility = 'visible';
		this._currentStep = 1;
		this._step2.style.visibility = 'hidden';
		this._stepDone.style.visibility = 'hidden';

		gsap.to(this._code, {opacity: 0.3, duration: 0.3});
	}

	private previewTheCode = () => {
		// @ts-ignore
		window.plausible('PreviewThePopup');
		Globals.LOAD_STORIES.addCodeToDOM();
		//navigator.clipboard.writeText(this._code.textContent);
	}

	resize() {
		super.resize();
	}
}
