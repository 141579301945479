import {GUI} from "dat.gui";
import {Globals} from "../data/Globals";
import {gsap} from "gsap";

export class LoadStories {

	private _dataToDisplay: any;
	private _storeCode;
	private _dataArray = [];

	private _timestamp;
	private _getCodeID;

	private _loadCallback: Function;

	public constructor(loadCallback) {
		this._loadCallback = loadCallback;
		this.init();
	}

	private init = () => {

		this._timestamp = new Date().getTime();

		// Create a new XMLHttpRequest object
		const xhr = new XMLHttpRequest();

// Define the URL of the JSON file
		// https://thetruestory.news/api/widget/main?with_icons=1&edition=ru&limit=9&period=7d&with_snipets=1
		const url = "https://undothefirewall.com/getNews";

// Configure the XMLHttpRequest
		xhr.open("GET", url, true);

		var _processData = this.processData;

// Set up a callback function to handle the response
		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				// The request is complete, and the response is successful

				// Parse the JSON response
				const jsonData = JSON.parse(xhr.responseText);
				var content = jsonData.content.top;
				_processData(content);
				// You can now work with the jsonData object
			//	console.log("JSON data loaded successfully:", content);
			} else if (xhr.readyState === 4 && xhr.status !== 200) {
				// The request completed with an error
				console.error("Error loading JSON data. Status code:", xhr.status);
			}
		};

		// Send the XMLHttpRequest
		xhr.send();
	}

	private processData = (data) => {

		var length = data.length;
		// CSS Styles
		var allDataOutput = '';
		var allDataOutputWithoutJS = '';

		var jsWrapper = "<script>const urlParams = new URLSearchParams(window.location.search);if (navigator.language === 'ru-RU' || navigator.language === 'ru' || navigator.language === 'ru-ru' || urlParams.has('" + this._timestamp + "')) {document.write(`";
		var jsWrapperEnd = "`)};</script>";


		var cssOutput = '';
	/*	cssOutput += '.mainContainer { all: revert;z-index:999999999999; background-color: rgba(0,0,0,0.7); width: 100vw; height: 100vh; position: fixed; top:0px; left: 0px}';
		cssOutput += '.centerContainer { all: revert;max-width: 900px; width: 90vw; max-height: 100vh; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)}';
		cssOutput += '.news { all: revert;background-color: #CFD7DF; padding: 40px; height: 400px; overflow-y: auto}';
		cssOutput += '.date { all: revert;color: #B54141; font-size: 12px; font-family: Arial; margin-bottom: 10px; border-bottom: solid 1px rgba(0,0,0,0.2); padding-bottom: 20px;}';
		cssOutput += '.headline { all: revert;font-size: 16px; font-family: Arial; margin-bottom: 10px; text-decoration: underline; color: black; clear: both;  }';
	cssOutput += '.source { all: revert;color: black; font-size: 12px; font-family: Arial; font-weight: bold; padding-top: 10px;  }';
		cssOutput += '.sourceImg { all: revert;border-radius: 32px; height: 32px; float: left; margin-bottom: 10px;margin-right: 10px}';*/

		cssOutput += '';


		allDataOutput += '<div style="all: revert;color: black; font-size: 48px; font-family: Arial; font-weight: bold; text-align: center; margin-top: 20px;">Что это?</div>';
		allDataOutput += '<div style="all: revert;color: black; font-size: 14px; font-family: Times New Roman; margin-top:30px; margin-bottom: 60px; max-width:450px;margin-left: auto; margin-right: auto;text-align: center">Мы поддерживаем распространение свободной информации о важных мировых событиях для всех россиян. Пожалуйста, прочтите новости ниже – это объективная новостная картина недели, составленная из материалов различных СМИ.</div>';
		allDataOutput += '<div style="all: revert;border-bottom: solid 1px rgba(0,0,0,0.2); margin-bottom: 20px;"></div>';


		var endMessageData = '<div style="all: revert;color: black; font-size: 16px; font-family: Times New Roman; margin-top:80px; margin-bottom: 20px; max-width:450px;margin-left: auto; margin-right: auto;text-align: center">Читайте главные новости в агрегаторе</div>';
		endMessageData += '<a href="https://thetruestory.news" target="_blank" style="all: revert;text-decoration: none;color: #ffffff; display: block; font-size: 10px; font-family: Arial; font-weight: bold; background-color: #B54141; width: 180px; height: 48px; border-radius: 48px; margin-top:0px; margin-bottom: 20px; max-width:450px;margin-left: auto; margin-right: auto;text-align: center; line-height: 48px;">TRUESTORY.NEWS</a>';
		endMessageData += '<a href="https://t.me/s/truestorymedia" target="_blank" style="all: revert;text-decoration: underline;color: #000000; display: block; font-size: 10px; font-family: Arial; font-weight: bold; margin-top:0px; margin-bottom: 50px; margin-left: auto; margin-right: auto;text-align: center;">Наш канал в Telegram</a>';


		for (var i = 0; i < length; i++) {


			var getStory = data[i];
			var getTitle = getStory.title;
			Globals.NEWS_DATA_TRANSLATED.push(getStory.title_en);
			getTitle = getTitle.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
			var getDate = getStory.snippet.source_date;

			var snippetText = getStory.snippet.text;
			//console.log(getStory);

			const dateTime = new Date(getDate);
			//console.log(dateTime)
			// Extract the date
			const year = dateTime.getFullYear();
			const month = dateTime.getMonth();
			const day = dateTime.getDate();

			var namedMonthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
			var namedMonthArrayRussian = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

			// Extract the time
			const hours = dateTime.getHours();
			const minutes = dateTime.getMinutes();
			const seconds = dateTime.getSeconds();

			////	console.log(`Date: ${year}-${month}-${day}`);
			//	console.log(`Time: ${hours}:${minutes}:${seconds}`);
			var stringMinutes = minutes.toString();
			if (minutes < 10) {
				stringMinutes = '0' + stringMinutes;
			}

			var combinedDate = day + ' ' + namedMonthArray[month] + ' ' + hours + ':' + stringMinutes;


			combinedDate = namedMonthArrayRussian[month] + ' ' + dateTime.getFullYear();
			// Date
			var dataOutput = '';


			// Headline
			dataOutput += '<a href="' + getStory.title_url + '" target="_blank"><div style="all: revert;font-size: 18px; font-family: Times New Roman; margin-bottom: 10px; text-decoration: underline; color: black;">' + getTitle + '</div></a>';
			dataOutput += '<div style="all: revert;font-size: 14px; font-family: Times New Roman; margin-bottom: 10px; color: black; ">' + snippetText + '</div>';



			//snippetText

			// Source Logo
			if (getStory.source.base64) {
			//	dataOutput += '<img style="all: revert;border-radius: 16px; height: 16px; float: left; margin-bottom: 10px;margin-right: 10px; float: left" src="' + getStory.snippet.source.base64 + '">';
			}
			// Source Name
			dataOutput += '<div style="all: revert;color: black; font-size: 12px; font-family: Arial; font-weight: bold; ">' + getStory.source.title.toUpperCase() + ' + <a href="' + getStory.url + '" target="_blank" style="all: revert; text-decoration: underline; color: black">' + getStory.source_count + ' источников</a> </div>';


			dataOutput += '<div style="clear: both; all: revert;color: #B54141; font-size: 12px; font-family: Arial; margin-bottom: 20px; border-bottom: solid 1px rgba(0,0,0,0.2); padding-bottom: 20px;">' + combinedDate + '</div>';
			allDataOutput += dataOutput;

			// Store in Array as Raw Data
			var storeDataObject = {date: combinedDate, headline: getTitle, source: getStory.source.title, sourceLogo: getStory.source.base64};
			this._dataArray.push(storeDataObject);

		}

		var outputWithoutJSWrapper = '<div style="all: revert;z-index:999999999999; background-color: rgba(0,0,0,0.7); width: 100vw; height: 100vh; position: fixed; top:0px; left: 0px"><div style="all: revert;max-width: 680px; width: 90vw; max-height: 100vh; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"><div style="position: absolute;right:-32px;top:-32px;cursor:pointer" onclick="function close(ref){ref.parentNode.parentNode.parentNode.removeChild(ref.parentNode.parentNode);} close(this);"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"><circle cx="32" cy="32" r="32" fill="#B54141"/> <path d="M35.7582 26.7876L32.4853 30.0605L29.2124 26.7876L27.2931 28.7069L30.566 31.9798L27.2729 35.2729L29.1922 37.1922L32.4853 33.8991L35.7784 37.1922L37.6977 35.2729L34.4046 31.9798L37.6775 28.7069L35.7582 26.7876Z" fill="#FFFBE9"/></svg></div><div style="all: revert;background-color: #ffffff; padding: 40px; height: calc(100vh - 200px); overflow-y: auto">' + allDataOutput + endMessageData + '</div></div></div>';

		var outputWithJSWrapper = jsWrapper + outputWithoutJSWrapper;

		// Format code
		var stringifyCode = outputWithJSWrapper.toString();
		stringifyCode += jsWrapperEnd;

		//var outputWithoutJSWrapper = cssOutput.toString() + '<div style="all: revert;z-index:999999999999; background-color: rgba(0,0,0,0.7); width: 100vw; height: 100vh; position: fixed; top:0px; left: 0px"><div style="all: revert;max-width: 680px; width: 90vw; max-height: 100vh; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"><div style="position: absolute;right:-32px;top:-32px;cursor:pointer" onclick="function close(ref){ref.parentNode.parentNode.parentNode.removeChild(ref.parentNode.parentNode);} close(this);"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"><circle cx="32" cy="32" r="32" fill="#B54141"/> <path d="M35.7582 26.7876L32.4853 30.0605L29.2124 26.7876L27.2931 28.7069L30.566 31.9798L27.2729 35.2729L29.1922 37.1922L32.4853 33.8991L35.7784 37.1922L37.6977 35.2729L34.4046 31.9798L37.6775 28.7069L35.7582 26.7876Z" fill="#FFFBE9"/></svg></div><div style="all: revert;background-color: #ffffff; padding: 40px; height: calc(100vh - 200px); overflow-y: auto">' + allDataOutput + '</div></div></div>'
		//console.log(outputWithoutJSWrapper)

		//

		//stringifyCode = stringifyCode.replaceAll('.', '\\\n. ');
		//stringifyCode = stringifyCode.replaceAll('>', '>\\\n ');
		stringifyCode = stringifyCode.replaceAll('<', ' &lt;');

		stringifyCode = stringifyCode.replaceAll('&lt;script>', '&lt;script><br>');
		stringifyCode = stringifyCode.replaceAll('if (navigator.language', '<br>if (navigator.language');
		stringifyCode = stringifyCode.replaceAll('{document.write(`', '{<br><indent />document.write(`<br>');


		stringifyCode = stringifyCode.replaceAll('&lt;style>', '<br>&lt;style><br><indent>');
		stringifyCode = stringifyCode.replaceAll('&lt;/style>', '</indent><br>&lt;/style><br><br>');
		//stringifyCode = stringifyCode.replaceAll('&lt;/style>', '&lt;/style><br>');

		stringifyCode = stringifyCode.replaceAll('&lt;div class="mainContainer">', '<br>&lt;div class="mainContainer"><br>');
		stringifyCode = stringifyCode.replaceAll('&lt;div class="centerContainer">', '<indent />&lt;div class="centerContainer"><br>');
		stringifyCode = stringifyCode.replaceAll('&lt;div class="news">', '<indent /><indent />&lt;div class="news"><br><indent />');

		stringifyCode = stringifyCode.replaceAll('&lt;div class="date">', '<br><br>&lt;div class="date">');


		stringifyCode = stringifyCode.replaceAll('&lt;/div> &lt;a href="', '&lt;/div><br>&lt;a href="');

		stringifyCode = stringifyCode.replaceAll('&lt;/a>', '&lt;/a><br>');

		stringifyCode = stringifyCode.replaceAll(' &lt;/div>', '&lt;/div>');
		stringifyCode = stringifyCode.replaceAll(' &lt;img', '<br>&lt;img');
		stringifyCode = stringifyCode.replaceAll(' &lt;div class="headline"', '<br>&lt;div class="headline"');
		stringifyCode = stringifyCode.replaceAll(' &lt;/a>', '<br>&lt;/a>');

		stringifyCode = stringifyCode.replaceAll('`)}; &lt;/script>', '<br><br>`)}&lt;/script>');
		stringifyCode = stringifyCode.replaceAll('}', '}<br> ');

		this._getCodeID = document.createElement('div');
		this._getCodeID.innerHTML = stringifyCode;

		this._storeCode = this._getCodeID.textContent;

		this._dataToDisplay = outputWithoutJSWrapper;

		this._loadCallback(this._dataArray);
	//	this.addCodeToDOM();
	}

	public getCodeDiv = () => {
		return this._getCodeID;
	}

	public addCodeToDOM = () => {
		var createDiv = document.createElement('div');
		createDiv.innerHTML = this._dataToDisplay;
		document.body.appendChild(createDiv);
	}

	public getTimestamp = () => {
		return this._timestamp;
	}
}
