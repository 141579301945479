import {WindowManager} from './utils/WindowManager';
import {ModuleFactory} from '../lib/com/hellomonday/core/ModuleFactory';
import {Globals} from './data/Globals';
import {ViewManager} from './managers/ViewManager';
import {MainView} from './views/MainView/MainView';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollSmoother} from "gsap/ScrollSmoother";

import {SplitText} from "gsap/SplitText";


import {LinkParser} from './utils/LinkParser';
import {MainMenu} from "./ui/MainMenu";

import {LoadStories} from "./utils/LoadStories";
import {gsap} from "gsap";
import {HeaderModule} from "./modules/HeaderModule/HeaderModule";
import {FAQModule} from "./modules/FAQModule/FAQModule";
import {NewsHeadlinesModule} from "./modules/NewsHeadlinesModule/NewsHeadlinesModule";
import {GetTheCodeModule} from "./modules/GetTheCodeModule/GetTheCodeModule";
import {HowItWorksModule} from "./modules/HowItWorksModule/HowItWorksModule";
import {SpreadTheWordModule} from "./modules/SpreadTheWordModule/SpreadTheWordModule";
import {CardModule} from "./modules/CardModule/CardModule";
import {HowItWorksVideoModule} from "./modules/HowItWorksVideoModule/HowItWorksVideoModule";
import {GoogleTranslationAPI} from "./utils/GoogleTranslationAPI";
import {HowItWorksSteps} from "./modules/HowItWorksSteps/HowItWorksSteps";
import {EmptyModule} from "./modules/EmptyModule/EmptyModule";
import {FooterModule} from "./modules/FooterModule/FooterModule";
import {AdvancedOptionModule} from "./modules/AdvancedOptionModule/AdvancedOptionModule";


const plugins = [ScrollToPlugin, ScrollTrigger, ScrollSmoother, SplitText];

const modules = {
	HeaderModule: HeaderModule,
	NewsHeadlinesModule: NewsHeadlinesModule,
	GetTheCodeModule: GetTheCodeModule,
	FAQModule: FAQModule,
	HowItWorksModule: HowItWorksModule,
	HowItWorksVideoModule: HowItWorksVideoModule,
	SpreadTheWordModule: SpreadTheWordModule,
	CardModule: CardModule,
	HowItWorksSteps: HowItWorksSteps,
	EmptyModule: EmptyModule,
	FooterModule: FooterModule,
	AdvancedOptionModule: AdvancedOptionModule
};

class Main {

	private _imagesToLoad = 71;
	private _imageLoaded = 0;

	private _mouseFollowElement;
	private _getVideo;
	private _skipIntroButton;
	private _introContainer;
	private _firstMouseMove: boolean = true;
	private _videoHasStarted: boolean = false;
	private _progressBar: HTMLElement;

	constructor() {
		gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText, ScrollSmoother)
		WindowManager.getInstance();

		Globals.VIEW_MANAGER = new ViewManager(document.body.querySelector('#ViewContainer'));
		LinkParser.getInstance(Globals.VIEW_MANAGER);

		ModuleFactory.registerModules(modules);
		Globals.LOAD_STORIES = new LoadStories(this.newsLoaded);



	}

	private dontShowIntroVideo = () => {
		this.removeIntro();
		Globals.HEADER_MODULE.animatedInFirst();
		Globals.MAIN_MENU.animateIn();
	}

	private setupIntroVideo = () => {
		document.body.style.overflowY = 'visible';
		var getMainView	= document.body.querySelector('.MainView');
		getMainView.style.height = '100%';

		this._getVideo = this._introContainer.querySelector('#MainIntro');
		this._mouseFollowElement = this._introContainer.querySelector('#PlayMainIntro');
		this._skipIntroButton = this._introContainer.querySelector('#SkipIntro');
		this._progressBar = this._introContainer.querySelector('#ProgressBar');

		this._getVideo.src = 'https://player.vimeo.com/progressive_redirect/playback/908542926/rendition/1080p/file.mp4?loc=external&log_user=0&signature=f4e427332f702fa4d7f2d6a33efbde41c5e412d879dad51587c0fde58d928ab2';
		this._getVideo.addEventListener('canplay', this.canPlayVideo);
	}

	private canPlayVideo = () => {
		this._getVideo.removeEventListener('canplay', this.canPlayVideo);
		gsap.to(this._getVideo, {duration: 0.4, opacity: 1});
		gsap.to(this._skipIntroButton, {delay: 1, duration: 0.3, y: 0, ease: 'power2.out'});

		this._skipIntroButton.addEventListener('mouseover', this.skipOver);
		this._skipIntroButton.addEventListener('mouseout', this.skipOut);
		document.body.addEventListener('mousemove', this.mouseFollow);
		document.body.addEventListener('click', this.clickVideo);
		this._skipIntroButton.addEventListener('click', this.skipIntro);
	}

	private skipOver = () => {
		gsap.to(this._skipIntroButton, {duration: 0.3, color: '#B54141', paddingBottom: '6px', borderBottomColor: '#B54141', ease: 'power2.out'});
	}

	private skipOut = () => {
		gsap.to(this._skipIntroButton, {duration: 0.3, color: '#FFFBE9', paddingBottom: '4px',borderBottomColor: '#FFFBE9', ease: 'power2.out'});
	}

	private mouseFollow = (e) => {
		//this._mouseFollowElement.style.left = e.clientX - 80 + 'px';
		//this._mouseFollowElement.style.top = e.clientY - 25 + 'px';
		var duration = 0.3;
		if (this._firstMouseMove) {
			duration = 0;
			this._firstMouseMove = false;
		}
		gsap.to(this._mouseFollowElement, {duration: duration, x: e.clientX - 80, y: e.clientY - 30});

		if (this._videoHasStarted === false && e.clientY > 0 && e.clientY < window.innerHeight - 80) {
			gsap.to(this._mouseFollowElement, {duration: 0.3, scale: 1, opacity: 1});
		}
		else {
			gsap.to(this._mouseFollowElement, {duration: 0.3, scale: 0, opacity: 0});
		}

	}

	private clickVideo = (e) => {
//console.log(this._getVideo)
		if (e.clientY > 0 && e.clientY < window.innerHeight - 80) {
		this._videoHasStarted = true;
		this._getVideo.play();
		//this._mouseFollowElement.style.display = 'none';
		//document.body.removeEventListener('mousemove', this.mouseFollow);
		gsap.to(this._mouseFollowElement, {duration: 0.3, scale: 0, opacity: 0});
		document.body.removeEventListener('click', this.clickVideo);
		this._getVideo.addEventListener('timeupdate', this.videoOnTime);
		}
	//	getVideo.addEventListener('ended', this.videoEnded);
	}

	private videoOnTime = (e) => {
		var getTime = this._getVideo.currentTime;
		gsap.to(this._progressBar, {width: (getTime / 57) * 100 + '%', duration: 1, ease: 'none'});

		if (getTime > 58) {
			this.skipIntro(true);
		}
	}

	private skipIntro = (isEnding) => {
		document.body.removeEventListener('mousemove', this.mouseFollow);
		this._getVideo.removeEventListener('timeupdate', this.videoOnTime);
		var useDelay = 0.2;
		if (isEnding === true) {
			useDelay = 2;
		}
		gsap.to(this._getVideo, {volume: 0, delay: useDelay, duration:1, ease: 'power1.in', onComplete: this.removeIntro});
		gsap.to(this._introContainer, {opacity: 0, duration:0.1, ease: 'power1.in'});

		this._skipIntroButton.removeEventListener('click', this.skipIntro);
		gsap.to(this._skipIntroButton, {duration: 0.3, y: 100, ease: 'power1.in'});
		Globals.HEADER_MODULE.animatedInFirst();
		Globals.MAIN_MENU.animateIn();
		var getMainView	= document.body.querySelector('.MainView');
		getMainView.style.height = 'auto';
	}


	private removeIntro = () => {

		this._introContainer.parentNode.removeChild(this._introContainer);
	}




	private newsLoaded = (data) => {
		Globals.NEWS_DATA = data;
		this.onTranslation();
	}

	private onTranslation = () => {
		//Globals.NEWS_DATA_TRANSLATED = data;
		this.preloadImages();
		this.init();

		this._introContainer = document.body.querySelector('#IntroContainer');

		if (Globals.IS_TOUCH_DEVICE) {
			this.dontShowIntroVideo();
		}
		else {
			this.setupIntroVideo();
		}
	}

	private preloadImages = () => {
		var getImageArea = document.body.querySelector('.crackedWall');
	//	console.log(getImageArea)
		var imagePath = 'assets/images/wall/Crack_alpha_000';
		for (var i = 14; i < 85; i++) {

			var getImage = new Image();
			Globals.CRACKED_IMAGES.push(getImage);
			getImage.onload = this.onImageLoaded;
			getImage.src = imagePath + i + '.png';
			getImage.style.visibility = 'hidden';
			getImage.width = 1920;
			getImage.height = 1080;
			if (i === 14) {
				getImage.style.visibility = 'visible';
			}
			getImageArea.appendChild(getImage);
			//console.log(getImage.src)
		}
	}

	private onImageLoaded = () => {
		this._imageLoaded++;
		//console.log(this._imageLoaded)
		if (this._imagesToLoad === this._imageLoaded) {
		//	this.init();
		}
	}


	private init = () => {


		Globals.MAIN_MENU = new MainMenu(document.querySelector('#MainMenu'));

		Globals.VIEW_MANAGER.registerView({name: 'MainView', view: MainView});
		Globals.VIEW_MANAGER.init();




		WindowManager.signalResize.add(this.resize);
		this.resize();


		var tl = gsap.timeline({
			scrollTrigger:
				{
					trigger: document.body, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '20vh',
					end: '2000vh'
				}
		});

		var headerModule = document.body.querySelector('.HeaderModule');
		//console.log(headerModule)
		var getInnerContainer = headerModule.querySelector('.innerContainer');
		tl.to(getInnerContainer, {
		//	backgroundPositionY: '200%',
			scale: 10,
			rotation: 10,
			ease: 'none'
		}, 0);
		var texture1 = document.body.querySelector('.texture1');
		tl.to(texture1, {
			scale: 3,
			rotation: 45,
			ease: 'none',
			transformOrigin:'50vw 50vh'
		}, 0);

		var texture2 = document.body.querySelector('.texture2');
		tl.to(texture2, {
			scale: 2,
			rotation: 20,
			transformOrigin:'50vw 50vh',
			ease: 'none',
		}, 0);

		var newsModule = document.body.querySelector('.NewsHeadlinesModule');

		gsap.to(newsModule, {
			//	backgroundPositionY: '200%',
			scale: 6,
			rotation: 10,
			ease: 'power1.in',
			duration: 500,
			scrollTrigger:
				{
					trigger: newsModule, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '100%',
					end: '300%'
				}
		});

		var headerInner = headerModule.querySelector('.inner');
		var newsInner = newsModule.querySelector('.inner');

		var howItWorksModule = document.body.querySelector('.HowItWorksVideoModule');


		gsap.to([headerModule, newsModule], {
			visibility: 'hidden',
			scrollTrigger:
				{
					trigger: howItWorksModule, // start the animation when ".box" enters the viewport (once)
					scrub: 0
				}
		});
		/*gsap.to(newsModule, {
			//	backgroundPositionY: '200%',
			scale: 10,
			rotation: 10,
			ease: 'none',
			duration: 500,
			scrollTrigger:
				{
					trigger: newsModule, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '100%',
					end: '200%'
				}
		});*/

		/*var ForegroundVideo = document.body.querySelector('#ForegroundVideo');
		gsap.to(ForegroundVideo, {
			//	backgroundPositionY: '200%',
			rotation: 180,
			repeat: -1,
			yoyo: true,
			ease: 'none',
			duration: 10
		});*/

	};

	public resize = () => {
		Globals.VIEW_MANAGER.resize();
	};
}

window.onload = () => {
	const main = new Main();
	(window as any).Main = main;
};
