import {AbstractModule} from '../../../lib/com/hellomonday/core/AbstractModule';
import {AbstractView} from '../../views/core/AbstractView';
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {Globals} from "../../data/Globals";

export class HeaderModule extends AbstractModule {
	private _undo: HTMLElement;
	private _the: HTMLElement;
	private _firewall: HTMLElement;
	private _element: HTMLElement;
	private _bringingRealNews: HTMLElement;

	private _arrowDown;

	private _texture1;
	private _texture2;

	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);

		this._element = element;
		this._undo = this.element.querySelector('.undo');
		this._the = this.element.querySelector('.the');
		this._firewall = this.element.querySelector('.firewall');
		this._bringingRealNews = this.element.querySelector('.bringingRealNews');
		this._arrowDown = this._element.querySelector('.arrowDown');
		this.init();
	}

	private init(): void {

		gsap.set([this._undo, this._the, this._firewall], {y: 100});


		//	console.log(this._element)
		var getInnerContainer = this._element.querySelector('.innerContainer');
		gsap.registerPlugin(SplitText)
// FIXME - animatie each letter individually - maybe we blur out each letter?
		gsap.set(getInnerContainer, {x: '-50%', y: '-50%'})
		gsap.set(this._element, {opacity: 0})

		Globals.HEADER_MODULE = this;

	}

	public animatedInFirst = () => {

		var duration = 0.5;
		var mainDelay = 1;
		gsap.to([this._undo], {delay: mainDelay, duration: duration, ease: 'power1.out', y: 0});
		gsap.to([this._the], {delay: 0.05 + mainDelay, duration: duration, ease: 'power1.out', y: 0});
		gsap.to([this._firewall], {delay: 0.1 + mainDelay, duration: duration, ease: 'power1.out', y: 0});
		var bringRealNews = this._element.querySelector('p');
		//bringRealNews.style.visibility = 'visible';
		gsap.to(bringRealNews, {delay: 0.2 + mainDelay, duration: duration, ease: 'power1.out', y: 0, autoAlpha: 1});

		gsap.set(this._element, {opacity: 0})
		gsap.to(this._element, {duration: 1, opacity: 1})

		var svgElement = this._element.querySelector('svg');
		gsap.set(svgElement, {scale: 2, opacity: 0});
		gsap.to(svgElement, {delay: 2, scale: 1, opacity: 1, duration: 0.8, ease: 'power1.out'});

		var getCircle = this._element.querySelector('circle');
		gsap.to(getCircle, {rotation: '+=360', duration: 2, repeat: -1, ease: 'none', transformOrigin: '50% 50%'});

		var getInnerContainer = this._element.querySelector('.innerContainer');
		gsap.set(getInnerContainer, {
			//	backgroundPositionY: '200%',
			scale: 0.2,
			rotation: 0,
			ease: 'none'
		});

		gsap.to(getInnerContainer, {
			//	backgroundPositionY: '200%',
			delay: 0,
			duration: 2,
			scale: 1,
			rotation: 0,
			ease: 'power2.out'
		});

		this._arrowDown.addEventListener('click', this.onArrowDownClick);

		var getNewsStoryElement = document.body.querySelector('.newsStoriesContainer');

		var splitUndo = new SplitText(this._undo.children[0], {type: "chars"});
		var splitThe = new SplitText(this._the.children[0], {type: "chars"});
		var splitFirewall = new SplitText(this._firewall.children[0], {type: "chars"});

		var scaleValue = 0.7;
		gsap.to(splitUndo.chars, {
			scaleX: scaleValue,
			scaleY: scaleValue,
			autoAlpha: 0,
			/*filter: 'blur(8px)',*/
			scale: Math.random() * 2,
			stagger: {
				amount: 3,
				from: "random"
			},
			ease: 'power2.in',
			duration: 25,
			scrollTrigger:
				{
					trigger: getNewsStoryElement, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '0vh',
					end: '175vh'
				}
		});

		gsap.to(splitThe.chars, {
			autoAlpha: 0,
			/*	filter: 'blur(8px)',*/
			scale: Math.random() * 2,
			stagger: {
				amount: 3,
				from: "random"
			},
			ease: 'power2.in',
			duration: 5,
			scrollTrigger:
				{
					trigger: getNewsStoryElement, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '17.5vh',
					end: '200vh'
				}
		});

		gsap.to(splitFirewall.chars, {
			scaleX: scaleValue,
			scaleY: scaleValue,
			autoAlpha: 0,
			/*	filter: 'blur(8px)',*/
			scale: Math.random() * 2,
			stagger: {
				amount: 3,
				from: "random"
			},
			ease: 'power2.in',
			duration: 5,
			scrollTrigger:
				{
					trigger: getNewsStoryElement, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '22vh',
					end: '210vh'
				}
		});


		gsap.to(this._bringingRealNews, {
			scaleX: scaleValue,
			scaleY: scaleValue,
			autoAlpha: 0,
			/*	filter: 'blur(8px)',*/
			stagger: {
				amount: 3,
				from: "random"
			},
			ease: 'power2.in',
			duration: 5,
			scrollTrigger:
				{
					trigger: getNewsStoryElement, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '40vh',
					end: '260vh'
				}
		});

	}

	private onArrowDownClick = () => {
		//console.log('onArrowDownClick');
		gsap.to(window, {duration: 1, scrollTo: {y: window.pageYOffset + window.innerHeight, autoKill: false}});
	}

	resize() {
		super.resize();
	}
}
