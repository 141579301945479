import {AbstractModule} from '../../../lib/com/hellomonday/core/AbstractModule';
import {AbstractView} from '../../views/core/AbstractView';
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {Globals} from "../../data/Globals";

export class CardModule extends AbstractModule {
	private _element;
	private _innerContainer;
	private _cardRef;

	private _timeline;
	private _splitBodyText;

	private _originalHeadline;

	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);
		this._element = element;
		//console.log(this._element)
		this._cardRef = this._element.getAttribute('data-card-ref');
		this._innerContainer = document.body.querySelector('#' + this._cardRef);
		this.init();
	}

	private init(): void {
		//console.log(this._innerContainer)

		var rotateValue = 2;
		if (this._cardRef === 'SolutionCard') {
			rotateValue = -2;
		} else if (this._cardRef === 'HowItWorksCard') {
			rotateValue = 3;
		}

		rotateValue = 0;

		gsap.set(this._innerContainer, {y: '200vh', rotate: rotateValue})
		requestAnimationFrame(this.delayedStart);

	}

	private delayedStart = () => {
		gsap.to(this._innerContainer, {
			rotate: 0,
			y: '-212px',
			ease: 'power4.out',
			duration: 5,
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					markers: false
				}
		});


		// Text anims
		var getHeadline = this._innerContainer.querySelector('.header');

		var getSubHeadline = this._innerContainer.querySelector('.subHeader');

		//this._originalHeadline = getSubHeadline.innerHTML;

		this._splitBodyText = Globals.nestedLinesSplit(getSubHeadline, {type: "lines"});


		gsap.set(this._splitBodyText.lines, {
			scaleX: 1,
			scaleY: 1,
			autoAlpha: 0,
			/*filter: 'blur(8px)'*/
		})


		gsap.to(this._splitBodyText.lines, {
			scaleX: 1,
			scaleY: 1,
			autoAlpha: 1,
		/*	filter: 'blur(0px)',*/
			stagger: {
				amount: 10,
				from: "random"
			},
			ease: 'power2.in',
			duration: 25,
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '-30%',
					end: '-15%'
				}
		});


		if (this._cardRef === 'HowItWorksCard') {


			// This is the 'cant be blocked in a central manner' text

			gsap.to(this._innerContainer, {
				opacity: 0,
				scrollTrigger:
					{
						trigger: this._element, // start the animation when ".box" enters the viewport (once)
						scrub: Globals.SCRUB_VALUE,
						start: '300%',
						end: '350%'
					}
			});

			var getHeader = this._innerContainer.querySelector('.header');
			var getSubHeader = this._innerContainer.querySelector('.header1');
			var getCracks = this._innerContainer.querySelector('.cracks');

			gsap.to([getHeader, getSubHeader], {
				y: '-100vh',
				ease: 'power2.in',
				duration: 5,
				scrollTrigger:
					{
						trigger: this._element,
						scrub: Globals.SCRUB_VALUE,
						markers: false,
						start: '50%',
					}
			});

			gsap.to([getCracks], {
				y: '0vh',
				opacity: 1,
				ease: 'power2.in',
				duration: 5,
				scrollTrigger:
					{
						trigger: this._element,
						scrub: Globals.SCRUB_VALUE,
						markers: false,
						start: '50%',
					}
			});

		//	gsap.set(getCracks.parentNode, {filter: 'blur(0.01px)'});


			// Fade out 'With your help we can..' text
			gsap.to(getCracks.parentNode, {
				opacity: 0,
				ease: 'power1.in',
				delay: 5,
				duration: 2,
			/*	filter: 'blur(8px)',*/
				scrollTrigger:
					{
						trigger: this._element,
						scrub: Globals.SCRUB_VALUE,
						markers: false,
						start: '130%',
						end: '175%',onUpdate: (self) => {
							/*console.log(
								"progress:",
								self.progress.toFixed(3),
								"direction:",
								self.direction,
								"velocity",
								self.getVelocity()
							);*/
						}
					}
			});

		} else {
			gsap.to(this._innerContainer, {
				opacity: 0,
				scrollTrigger:
					{
						trigger: this._element, // start the animation when ".box" enters the viewport (once)
						scrub: Globals.SCRUB_VALUE,
						start: '120%',
						end: '150%'
					}
			});
		}
	}


	public resize = () => {
		/*if (this._splitBodyText) {
			//this._splitBodyText.revert();
			var getSubHeadline = this._innerContainer.querySelector('.subHeader');
			this._splitBodyText.revert();
			this._splitBodyText = Globals.nestedLinesSplit(getSubHeadline, {type: "lines"});
			gsap.killTweensOf(this._splitBodyText.lines);
			gsap.set(this._splitBodyText.lines, {
				scaleX: 1,
				scaleY: 1,
				autoAlpha: 0,

			})


			gsap.to(this._splitBodyText.lines, {
				scaleX: 1,
				scaleY: 1,
				autoAlpha: 1,

				stagger: {
					amount: 10,
					from: "random"
				},
				ease: 'power2.in',
				duration: 25,
				scrollTrigger:
					{
						trigger: this._element, // start the animation when ".box" enters the viewport (once)
						scrub: Globals.SCRUB_VALUE,
						start: '-30%',
						end: '5%'
					}
			});


		}*/

		var getCrackContainer = this._innerContainer.querySelector('.crackedWall');
		var getCracks = this._innerContainer.querySelector('.crackedWall').children[0];
		var width = getCracks.offsetWidth;
	//	console.log(width)
		if (window.innerWidth < width)
		{
			getCrackContainer.style.left = window.innerWidth / 2 - width / 2 + 'px';
		}
		else {
			getCrackContainer.style.left = 0 + 'px';

		}


		super.resize();
	}
}
