import { AbstractModule } from '../../../lib/com/hellomonday/core/AbstractModule';
import { AbstractView } from '../../views/core/AbstractView';
import {gsap} from "gsap";

export class AdvancedOptionModule extends AbstractModule {


	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);

		this.init();
	}

	private init(): void {

	}


	resize() {
		super.resize();
	}
}
