import { AbstractModule } from '../../../lib/com/hellomonday/core/AbstractModule';
import { AbstractView } from '../../views/core/AbstractView';
import {gsap} from "gsap";

export class FAQModule extends AbstractModule {
	private _undo: HTMLElement;
	private _the: HTMLElement;
	private _firewall: HTMLElement;

	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);

		/*this._undo = this.element.querySelector('.undo');
		this._the = this.element.querySelector('.the');
		this._firewall = this.element.querySelector('.firewall');*/
		this.init();
	}

	private init(): void {



	}

	resize() {
		super.resize();
	}
}
