import { detect } from 'detect-browser';

declare class DocumentTouch {}

import { ViewManager } from '../managers/ViewManager';

import {MainMenu} from "../ui/MainMenu";

import {LoadStories} from "../utils/LoadStories";
import {GoogleTranslationAPI} from "../utils/GoogleTranslationAPI";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";

export class Globals {
	public static DEBUG = {use_video: false, showHuman: true, neverHideTheMainMenu: true};
	public static MOVEMENT = {allowHips: false, allowLeftLeg: false, allowRightLeg: false, allowRightArm: true, allowLeftArm: true, allowNeck: false, allowFacialExpressions: false};

	public static RECORD_MODE:boolean = false;

	public static DATA_EXPORT: any = {language: 'american', sign: 'hello', requiresMotion: true, type: 'static', allowRightHandAndArm: true, allowLeftHandAndArm: true, frames: []};
	public static CURRENT_FRAME = {dorminant: true, nonDorminant: false};

	public static MATCH_MOVEMENTS:boolean = false;

	public static ADJUSTMENTS = {rightHandX: 0, rightHandY: 0, rightHandZ: 0, leftHandX: 0, leftHandY: 0, leftHandZ: 0};
	public static USE_RIGHT_HAND_POSE = null;
	public static USE_LEFT_HAND_POSE = null;

	public static GOOGLE_TRANSLATION_API:GoogleTranslationAPI;


	public static MAIN_MENU:MainMenu;
	public static NEWS_DATA;
	public static NEWS_DATA_TRANSLATED = [];

	public static HEADER_MODULE;

	public static CRACKED_IMAGES = [];

	public static SCRUB_VALUE = 1;


	public static moveLegsAndHips = false;

	public static SHOULDER_DISTANCE = 0;

	public static isExporting = false;

	public static FINGER_DATA = {rightHand: [], body:[], leftHand: []};

	public static MIXAMO_MODELS_DIFFERENCE_X = 0;



	public static COLORS = {red: 0xFF0000, blue: 0x0000ff};

	public static SITE_WRAPPER: HTMLDivElement = document.getElementById('App') as HTMLDivElement;
	public static IS_TOUCH_DEVICE: boolean = 'ontouchstart' in window || ((window as any).DocumentTouch && document instanceof DocumentTouch); // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
	public static IS_IE: boolean = false;

	private static DOCUMENT_TOUCH: DocumentTouch = (window as any).DocumentTouch;
	public static IS_MOBILE: boolean = false;
	public static IS_ANDROID: boolean = navigator.userAgent.toLowerCase().indexOf('android') > -1;
	public static IS_IOS: boolean = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

	public static VIEW_MANAGER: ViewManager;



	public static LOAD_STORIES: LoadStories;

	public static browser = detect();

	public static nestedLinesSplit = (target, vars) => {
		target = gsap.utils.toArray(target);
		if (target.length > 1) {
			let splits = target.map(t => Globals.nestedLinesSplit(t, vars)),
				result = splits[0],
				resultRevert = result.revert;
			result.lines = splits.reduce((acc, cur) => acc.concat(cur.lines), []);
			result.revert = () => splits.forEach(s => s === result ? resultRevert() : s.revert());
			return result;
		}
		target = target[0];
		let contents = target.innerHTML;
		gsap.utils.toArray(target.children).forEach(child => {
			let split = new SplitText(child, {type: "lines"});
			split.lines.forEach(line => {
				let clone = child.cloneNode(false);
				clone.innerHTML = line.innerHTML;
				target.insertBefore(clone, child);
			});
			target.removeChild(child);
		});
		let split = new SplitText(target, vars),
			originalRevert = split.revert;
		split.revert = () => {
			originalRevert.call(split);
			target.innerHTML = contents;
		};
		return split;
	}

}
