import { View } from './IView';
import { ModuleFactory } from '../../../lib/com/hellomonday/core/ModuleFactory';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';
import { LinkParser } from '../../utils/LinkParser';

export class AbstractView implements View {
	protected element: HTMLElement;
	public name: string;
	public modules = new Map();
	public initial: boolean;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		this.name = name;
		this.element = element;
		this.initial = initial;

		LinkParser.getInstance().parseNodeList(element.querySelectorAll('a'));
	}

	protected buildModules() {
		let moduleData = this.element.querySelectorAll('[data-module]');

		let i = 0;
		let l = moduleData.length;
		let module;
		let moduleName: string;

		for (i; i < l; i++) {
			moduleName = moduleData[i].getAttribute('data-module');

			if (moduleName) {
				module = ModuleFactory.create(moduleName, moduleData[i], this);

				if (module) {
					this.modules.set(moduleName, module);
				}
			}
		}
	}

	public in() {
		this.buildModules();

		Globals.VIEW_MANAGER.addView(this.element);
		gsap.to(this.element, 0.5, { opacity: 1, onComplete: this.inComplete, overwrite: true });
	}

	public inComplete() {
		Globals.VIEW_MANAGER.allowNextView();
	}

	public out() {
		gsap.to(this.element, 0.5, { opacity: 0, onComplete: this.outComplete, overwrite: true });
	}

	public outComplete = () => {
		this.kill();
		Globals.VIEW_MANAGER.allowNextView();
	};

	public resize() {
		this.modules.forEach(mod => {
			mod.resize();
		});
	}

	public kill() {
		Globals.VIEW_MANAGER.removeView(this.element);

		this.modules.forEach(module => {
			module.kill();
		});
	}
}
