import {ViewManager} from '../managers/ViewManager';
import {gsap} from "gsap";
import {Globals} from "../data/Globals";

export class MainMenu {


    private _mainMenuElement: HTMLElement;

	private _getTheCode: HTMLElement;
	private _theProblem: HTMLElement;
	private _theSolution: HTMLElement;
	private _faq: HTMLElement;

    private _isShown: boolean = true;


	constructor(element) {
		this._mainMenuElement = element;
        this.init();
    }

    private init = () => {
		this._getTheCode = this._mainMenuElement.querySelector('.getTheCode');
		this._theProblem = this._mainMenuElement.querySelector('.theProblem');
		this._theSolution = this._mainMenuElement.querySelector('.theSolution');
		this._faq = this._mainMenuElement.querySelector('.faq');

		this._theProblem.addEventListener('click', this.scrollTheProblem);
		this._theSolution.addEventListener('click', this.scrollTheSolution);
		this._getTheCode.addEventListener('click', this.scrollToGetTheCode);
		this._faq.addEventListener('click', this.scrollFAQ);


		gsap.set([this._getTheCode, this._theProblem, this._theSolution, this._faq], {y: -120, opacity: 0});



    }

	private scrollToGetTheCode = () => {
		gsap.to(window, {duration: 0.3, scrollTo: {y: '#GetTheCode', offsetY: 0}});
	}


	private scrollTheProblem = () => {
		gsap.to(window, {duration: 0.3, scrollTo: {y: '#TheProblem', offsetY: 0}});
	}

	private scrollTheSolution = () => {
		gsap.to(window, {duration: 0.3, scrollTo: {y: '#TheSolution', offsetY: 0}});
	}

	private scrollFAQ = () => {
		gsap.to(window, {duration: 0.3, scrollTo: {y: '#FAQ', offsetY: 0}});
	}

	public animateIn = () => {
		gsap.to(this._getTheCode, {delay: 0, duration: 0.4, y: 0, opacity: 1});

	}

    private startExperience = () => {
        this._mainMenuElement.style.pointerEvents = 'none';
        this.hideMainMenu();

    }

    public hideMainMenu = () => {
        if (this._isShown) {
            this._isShown = false;
            gsap.to(this._mainMenuElement, 0.5, {opacity: 0, onComplete: this.hideMainMenuComplete});
        //    Globals.VOICE_COMMANDS.play('cameraStepBack');
        }
    }

    private hideMainMenuComplete = () => {
        this._mainMenuElement.style.display = 'none';
    }

    public showMainMenu = () => {
        if (this._isShown === false) {
            this._isShown = true;
            this._mainMenuElement.style.display = 'block';
            gsap.to(this._mainMenuElement, 0.5, {opacity: 1, onStart: this.showMainMenuComplete});
        }
    }

    private showMainMenuComplete = () => {
        this._mainMenuElement.style.display = 'block';
    }


}

