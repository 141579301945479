import {AbstractModule} from '../../../lib/com/hellomonday/core/AbstractModule';
import {AbstractView} from '../../views/core/AbstractView';
import {gsap} from "gsap";
import {Globals} from "../../data/Globals";
import {LoadStories} from "../../utils/LoadStories";
import {GoogleTranslationAPI} from "../../utils/GoogleTranslationAPI";

export class NewsHeadlinesModule extends AbstractModule {
	private _element: HTMLElement;


	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);

		this._element = element;
		//	console.log(element)

		this.init();
	}

	private init(): void {
		this.newsLoaded();

	}

	private newsLoaded = () => {
		//console.log(this._element)
		var data = Globals.NEWS_DATA;

		// Translate certain data


		//Globals.GOOGLE_TRANSLATION_API = new GoogleTranslationAPI();
		//	Globals.GOOGLE_TRANSLATION_API.translate('fish');

		var getText = [];


		for (var i = 0; i < 10; i++) {
			var getNewsStory = data[i];
			getText.push(getNewsStory.headline);
		}




		var getNewsStories = this._element.querySelectorAll('.newsStory');
		//gsap.set(getNewsStories, {filter: 'blur(18px)', scaleX: 0.4, scaleY: 0.4, autoAlpha: 0});
		gsap.set(getNewsStories, {filter: 'blur(0px)', scaleX: 0.4, scaleY: 0.4, autoAlpha: 0});


//, x: Math.random() * 100 + '%', y: Math.random() * 100 + '%',
		var delay = Math.random() * 2;


		var tl = gsap.timeline({
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '30%',
					end: '80%'
				}
		});
		tl.to(getNewsStories, {
			autoAlpha: 1,
			scaleX: 1,
			scaleY: 1,
		/*	filter: 'blur(0px)',*/
			ease: 'power2.out',
			stagger: 0.1

		});
		/*tl.to(getNewsStories, {
			autoAlpha: 0,
			scaleX: 1.6,
			scaleY: 1.6,
			filter: 'blur(8px)',
			ease: 'power3.in',
			stagger: 0.2

		}, 2);*/


		var getNewsStoryContainer = this._element.querySelector('.newsStoriesContainer');
		for (var i = 0; i < getNewsStoryContainer.children.length; i++) {
			var getNewsStory = data[i];
			var getNewsStoryElement = getNewsStoryContainer.children[i];
			getNewsStoryElement.children[0].innerHTML = getNewsStory.date;
			getNewsStoryElement.children[1].innerHTML = getNewsStory.headline;
			getNewsStoryElement.children[2].innerHTML = getNewsStory.source;

			//	gsap.set(getNewsStoryElement, {x: window.innerWidth * Math.random() - 300, y: window.innerHeight * Math.random()});


			// FIXME - Implement so that the ones that are furthest behind - gets the least amount of movement
			//(getNewsStoryContainer.children.length - i);
			var getOpacity = Number(getComputedStyle(getNewsStoryElement).getPropertyValue('opacity'));
			//console.log(getOpacity)
			var calcNewY = 600 * getOpacity;
			//console.log(calcNewY)


			/*	gsap.to(getNewsStoryElement, {
					filter: 'blur(10px)',
					ease: 'power2.in',
					duration: 5,
					delay: Math.random() * 2 + 2,
					scrollTrigger:
						{
							trigger: getNewsStoryElement, // start the animation when ".box" enters the viewport (once)
							scrub: Globals.SCRUB_VALUE,
							start: '600px',
							end: '1400px'
						}
				});*/

		}

		this.onTranslation(Globals.NEWS_DATA_TRANSLATED)

	}

	private onTranslation = (data) => {

		//console.log(data)
		var getNewsStoryContainer = this._element.querySelector('.newsStoriesContainer');
		for (var i = 0; i < 10; i++) {
			var getData = data[i];
			var getNewsStoryElement = getNewsStoryContainer.children[i];
			getNewsStoryElement.children[1].innerHTML = getData;
		}
	}

	resize() {
		super.resize();
	}
}
