import { ViewManager } from '../managers/ViewManager';

export class LinkParser {
	private static instance: LinkParser;
	public static viewManager: ViewManager;

	private constructor(viewManager: ViewManager) {
		LinkParser.viewManager = viewManager;
	}

	public static getInstance(viewManager?: ViewManager): LinkParser {
		if (!LinkParser.instance) {
			if (!viewManager) {
				console.error('First LinkParser.getInstance must receive a ViewManager instance as the first parameter');
			}
			LinkParser.instance = new LinkParser(viewManager);
		}

		return LinkParser.instance;
	}

	public parse(element: HTMLElement, callback?: Function) {
		if (callback) {
			(element as any)._callback = callback;
		}

		element.addEventListener('click', this.onClick);
	}

	public parseNodeList(nodeList: NodeListOf<HTMLAnchorElement>, callback?: Function) {
		for (let i = 0; i < nodeList.length; i++) {
			if (!nodeList[i].classList.contains('noLinkParse')) {
				this.parse(nodeList[i], callback);
			}
		}
	}

	public kill(element) {
		element.removeEventListener('click', this.onClick);

		if (element._callback) {
			element._callback = null;
		}
	}

	public killNodeList(nodeList: HTMLCollectionOf<HTMLElement>) {
		for (let i = 0; i < nodeList.length; i++) {
			if (!nodeList[i].classList.contains('noLinkParse')) {
				this.kill(nodeList[i]);
			}
		}
	}

	private onClick = event => {
		event.preventDefault();

		if (event.currentTarget._callback) {
			event.currentTarget._callback(event.currentTarget);
		}

		let href = event.currentTarget.href || event.target.parentNode.href;

		if (href === undefined) {
			href = '';
		}

		if (this.isInternal(href)) {
			let base = document.location.protocol + '//' + window.location.host + '/';

			let path = href.replace(base, '');

			LinkParser.viewManager.setPath(path);
		} else {
			if (href.indexOf('mailto') !== -1) {
				window.location.href = href;
			} else {
				window.open(href, '_blank');
			}
		}
	};

	public click(href) {
		if (this.isInternal(href)) {
			let base = document.location.protocol + '//' + window.location.host + '/';

			let path = href.replace(base, '');

			LinkParser.viewManager.setPath(path);
		} else {
			if (href.indexOf('mailto') !== -1) {
				window.location.href = href;
			} else {
				window.open(href, '_blank');
			}
		}
	}

	private isInternal(url) {
		let valid = false;

		if (url.indexOf(window.location.hostname) !== -1) {
			valid = true;
		}

		if (isFile(url)) {
			valid = false;
		}

		return valid;
	}
}

export function isFile(pathname: string) {
	return (
		pathname
			.split('/')
			.pop()
			.split('.').length > 1
	);
}
