import {AbstractModule} from '../../../lib/com/hellomonday/core/AbstractModule';
import {AbstractView} from '../../views/core/AbstractView';
import {gsap} from "gsap";
import {Globals} from "../../data/Globals";

export class HowItWorksVideoModule extends AbstractModule {
	private _element: HTMLElement;
	private _popUpCard: HTMLElement;

	private _animCount = {frame: 0};

	private _crackedWallImage;
	private _crackedWallVideo;

	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);
		this._element = element;
		this.init();
	}

	private init(): void {

		this._popUpCard = document.querySelector('.cardPopupHowItWorks');
		//	gsap.set(this._popUpCard, {y: '100vh'});

		requestAnimationFrame(this.delayedStart);

	}

	private crackedWallAnim = () => {
		var getFrame = Math.round(this._animCount.frame);
		//console.log(getFrame)
		//console.log(Globals.CRACKED_IMAGES.length)
//console.log(getFrame)
		var imageToShow = Globals.CRACKED_IMAGES[getFrame];
//console.log(imageToShow)
		imageToShow.decode().then(() => {
			//console.log('iage decoded')
			for (var i = 0; i < Globals.CRACKED_IMAGES.length; i++) {
				//console.log(i)
				var getCrackedImage = Globals.CRACKED_IMAGES[i];
				//console.log(getCrackedImage);
				if (i === getFrame) {

				} else {
					if (getCrackedImage.style.visibility !== 'hidden') {
						getCrackedImage.style.visibility = 'hidden';

					}
				}
			}
			imageToShow.style.visibility = 'visible';
		});

		//this._crackedWallImage.src = Globals.CRACKED_IMAGES[getFrame].src;
		//	console.log(this._crackedWallImage.src)

	//	console.log(getFrame)
		//	console.log(this._animCount.frame)
		//console.log(this._crackedWallVideo.duration);
		//this._crackedWallVideo.currentTime = this._animCount.frame;
	}

	private startAnim = () => {
		/*	var getCard1 = document.querySelector('#ProblemCard');
			var getCard2 = document.querySelector('#SolutionCard');

			getCard1.style.backgroundColor = 'transparent';
			getCard2.style.backgroundColor = 'transparent';*/
	}

	private delayedStart = () => {
		var getVideo = document.querySelector('#CrackVideo');
		var getCrackedWall = document.querySelector('.crackedWall');
		this._crackedWallImage = getCrackedWall.querySelector('img');
		this._crackedWallVideo = getVideo;
		//	console.log(getCrackedWall)


		gsap.to(this._animCount, {
			onStart: this.startAnim,
			frame: 70,
			ease: 'none',
			duration: 5,
			onUpdate: this.crackedWallAnim,
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: 0,
					markers: false,
					start: '0%',
					end: '100%'
				}
		});


		var tl = gsap.timeline({
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					start: '90%',
					end: '170%'
				}
		});


	}


	resize() {
		super.resize();
	}
}
