import { buildComponent } from './ModuleFactory';
import { AbstractView } from '../../../../src/views/core/AbstractView';

export class AbstractModule {
	public element = null;
	public view = null;

	public killed = false;
	public animatedIn = false;

	public triggers = [];
	public components = [];

	constructor(element: HTMLElement, view: AbstractView) {
		this.element = element;
		this.view = view;
		this.buildComponents();
	}

	buildComponents() {
		var data = this.element.querySelectorAll('[data-component]');

		var i = 0;
		var l = data.length;
		var component;
		var name;

		for (i; i < l; i++) {
			name = data[i].getAttribute('data-component');

			if (name) {
				component = buildComponent(name, data[i], this);

				if (component) {
					this.components.push(component);
				}
			}
		}
	}

	public resize() {
		var l = this.triggers.length;

		// console.log('abstract module resizing');

		/*		for (var i = 0; i < l; i++) {
			if (this.triggers[i].triggered) {
				continue;
			}
			this.triggers[i].updateBounds(Globals.SCROLL_CONTROLLER.viewport);
			this.triggers[i].check(Globals.SCROLL_CONTROLLER.viewport);
		}*/

		l = this.components.length;

		for (let i = 0; i < l; i++) {
			this.components[i].resize();
		}
	}

	public checkTriggers = viewport => {
		if (this.triggers.length > 0) {
			var l = this.triggers.length;

			for (var i = 0; i < l; i++) {
				if (this.triggers[i].triggered) {
					continue;
				}
				this.triggers[i].check(viewport);
			}
		}
	};

	public kill() {
		this.killed = true;

		var l = this.components.length;

		for (var i = 0; i < l; i++) {
			this.components[i].kill();
		}
	}
}
