import { AbstractModule } from '../../../lib/com/hellomonday/core/AbstractModule';
import { AbstractView } from '../../views/core/AbstractView';
import {gsap} from "gsap";

export class FooterModule extends AbstractModule {
	private _undo: HTMLElement;
	private _the: HTMLElement;
	private _firewall: HTMLElement;

	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);

		this.init();
	}

	private init(): void {


		this.faqFunctionality();
	}

	private faqFunctionality = () => {
		var getQuestions = this.element.querySelectorAll('.question');
		var getAnswers = this.element.querySelectorAll('.answer');

		if (getQuestions.length > 0) {
			for (var i = 0; i < getQuestions.length; i++) {
				var getElement = getQuestions[i];
				getElement._isOpen = false;
				getElement._answerElement = getAnswers[i];
				getElement._circleButton = getElement.querySelector('.plusCircle');
				getElement._line1 = getElement.querySelector('.line1');
				getElement._line2 = getElement.querySelector('.line2');
				getElement.addEventListener('click', this.clickFAQ);

			}
		}

	}

	private clickFAQ = (event) => {
		var getTarget = event.currentTarget;
		if (getTarget._isOpen === true) {
			getTarget._isOpen = false;
			gsap.to(getTarget._answerElement, {duration: 0.2, ease: 'power1.inOut', height: 0});
			gsap.to(getTarget._circleButton, {duration: 0.2, ease: 'power1.inOut', backgroundColor: '#252423'});
			gsap.to(getTarget._line1, {duration: 0.2, ease: 'power1.inOut', scaleY: 1, backgroundColor: '#FFFBE9'});
			gsap.to(getTarget._line2, {duration: 0.2, ease: 'power1.inOut', scaleY: 1, backgroundColor: '#FFFBE9'});
		}
		else {
			getTarget._isOpen = true;
			gsap.to(getTarget._answerElement, {duration: 0.2, ease: 'power1.inOut', height: 'auto'});
			gsap.to(getTarget._circleButton, {duration: 0.2, ease: 'power1.inOut', backgroundColor: '#B54141'});

			gsap.to(getTarget._line1, {duration: 0.2, ease: 'power1.inOut', scaleY: 1}); //, backgroundColor: '#252423'
			gsap.to(getTarget._line2, {duration: 0.2, ease: 'power1.inOut', scaleY: 0}); // , backgroundColor: '#252423'
		}
	}

	resize() {
		super.resize();
	}
}
