import {AbstractModule} from '../../../lib/com/hellomonday/core/AbstractModule';
import {AbstractView} from '../../views/core/AbstractView';
import {gsap} from "gsap";
import {Globals} from "../../data/Globals";

export class SpreadTheWordModule extends AbstractModule {
	private _element: HTMLElement;

	private _join: HTMLElement;
	private _header1: HTMLElement;
	private _header2: HTMLElement;
	private _hashtag: HTMLElement;

	private _graphics1: HTMLDivElement;
	private _graphics2: HTMLDivElement;

	private _graphicsImage1: HTMLElement;
	private _graphicsImage2: HTMLElement;

	private _graphicsArea: HTMLElement;

	private _header;

	constructor(element: HTMLElement, view: AbstractView) {
		super(element, view);
		this._element = element;
		this._header = this._element.querySelector('.header');

		this._join = this._element.querySelector('.join');
		this._header1 = this._element.querySelector('.header');
		this._header2 = this._element.querySelector('.header');
		this._hashtag = this._element.querySelector('.hashtag');

		this._graphicsArea = this._element.querySelector('.graphics')
		this._graphics1 = this._element.querySelector('.graphics1');
		this._graphics2 = this._element.querySelector('.graphics2');
		this._graphicsImage1 = this._graphics1.querySelector('.image');
		this._graphicsImage2 = this._graphics2.querySelector('.image');
		//gsap.set([this._join, this._header1, this._header2, this._hashtag], {autoAlpha: 0});
		gsap.set(this._graphicsImage1, {rotationX: 250, rotationZ: -10 * 40, z: -10000, y: 0, x: 6500});
		gsap.set(this._graphicsImage2, {rotationX: 25, rotationZ: 10 * 40, z: -10000, y: 0, x: -6500});
		this.init();
	}

	private init(): void {
		requestAnimationFrame(this.delayedStart);
	}

	private delayedStart = () => {


		/*gsap.to(this._join, {
			autoAlpha: 1,
			ease: 'power1.in',
			duration: 50,
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					markers: false,
					end: '-20%'
				}
		});

		gsap.to(this._header1, {
			autoAlpha: 1,
			filter: 'blur(0px)',
			ease: 'power1.in',
			duration: 50,
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					markers: false,
					end: '-15%'
				}
		});

		gsap.to(this._header2, {
			autoAlpha: 1,
			filter: 'blur(0px)',
			ease: 'power1.in',
			duration: 50,
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					markers: false,
					end: '-15%'
				}
		});

		gsap.to(this._hashtag, {
			autoAlpha: 1,
			filter: 'blur(0px)',
			ease: 'power1.out',
			duration: 50,
			scrollTrigger:
				{
					trigger: this._element, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					markers: false,
					end: '-5%'
				}
		});*/


		// Graphics Scroll Animation


		gsap.to(this._graphicsImage1, {
			rotationX: 0,
			rotationY: 0,
			rotationZ: 0,
			z: 0,
			y: 0,
			x: 0,
			ease: 'power1.out',
			duration: 50,
			scrollTrigger:
				{
					trigger: this._header, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					markers: false,
					start: '30%',
					end: '120%'
				}
		});

		gsap.to(this._graphicsImage2, {
			rotationX: 0,
			rotationY: 0,
			rotationZ: 0,
			z: 0,
			y: 0,
			x: 0,
			ease: 'power1.out',
			duration: 50,
			scrollTrigger:
				{
					trigger: this._header, // start the animation when ".box" enters the viewport (once)
					scrub: Globals.SCRUB_VALUE,
					markers: false,
					start: '20%',
					end: '140%'
				}
		});
	}


	resize() {
		super.resize();
	}
}
